import styled from 'styled-components';
import config from '../../components/config';

const LayoutThanksForApplying = styled.section`
  padding-top: 4.8rem;

  @media (max-width: ${config.resolutions.tablet}) {
    padding-top: 2.4rem;

    & p {
      margin-bottom: 2.4rem;
    }
  }
`;

const HeaderThanksFroApplying = styled.header`
  display: flex;
  margin-bottom: 3.2rem;

  @media (max-width: ${config.resolutions.tablet}) {
    display: block;
    margin-bottom: 1.6rem;
  }
`;

const Icon = styled.img`
  margin-right: 4.97rem;
  @media (max-width: ${config.resolutions.tablet}) {
    margin-bottom: 1.6rem;
  }
`;

export { LayoutThanksForApplying, HeaderThanksFroApplying, Icon };
