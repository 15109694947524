import React from 'react';
import { getLinkByPageName } from '../../lib/links';
import { Text3 } from '../../components/atoms/body';
import { Title2 } from '../../components/atoms/headers';
import ThankYouTitleProps from '../interfaces/ThankYouTitle';
import {
  LayoutThanksForApplying,
  HeaderThanksFroApplying,
  Icon
} from './style';

function ThankYouTitle(props: ThankYouTitleProps) {
  const { title, subtitles } = props;
  return (
    <LayoutThanksForApplying>
      <HeaderThanksFroApplying>
        <a href={getLinkByPageName('home')}>
          <Icon
            src="/assets/icons/ironhack_logos/logo.svg"
            alt="Ironhack Logo"
          />
        </a>
        <Title2>{title}</Title2>
      </HeaderThanksFroApplying>
      {subtitles.map((subtitle, index) => {
        return (
          <Text3 as="p" key={index}>
            {subtitle}
          </Text3>
        );
      })}
    </LayoutThanksForApplying>
  );
}

export default ThankYouTitle;
